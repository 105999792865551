import React from "react";
import InterviewStyle from "./css/Interview.module.css";
import TourImage from "../../images/interview-images/2024-tour.jpg";
import SheetImage from "../../images/interview-images/speaker-sheet.jpg";
import { Image } from "react-bootstrap";
import GalleryContainer from "../SupstanceAbuse/GalleryContainer";
import SubTitleA from "../SupstanceAbuse/SubTitleA";
import GalleryVideoContainer1 from "../SupstanceAbuse/GalleryVideoContainer1";
import GalleryVideoContainer from "../SupstanceAbuse/GalleryVideoContainer";

const DesktopInterview = () => {
  const mainCon = InterviewStyle.mainCon;
  const imgCornerLeft = InterviewStyle.imgCornerLeft;
  const imgCornerRight = InterviewStyle.imgCornerRight;
  const contentCon = InterviewStyle.contentCon;
  const h1Style = InterviewStyle.h1Style;
  const videoCon = InterviewStyle.videoCon;

  const videoSection = (
    <>
      <div>
        <div
          style={{
            padding: "0 2rem",
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          id="PasterSection"
        >
          <b>Overcoming Addiction </b>: <br />
          With Determination and Degrees
        </div>
        <GalleryVideoContainer1 />
      </div>
    </>
  );

  const videoSection2 = (
    <>
      <div
        style={{
          padding: "0 2rem",
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <b>Understanding Addiction</b>: <br />
        The Obsession Behind the First Hit
        <GalleryVideoContainer />
      </div>
    </>
  );

  return (
    <>
      <main id="tour" className={mainCon}>
        <div className={imgCornerLeft}></div>
        <div className={imgCornerRight}></div>
        <section className={contentCon}>
          <h1 className={h1Style}>
            <span>Life's Chapter 1</span>
          </h1>
          <h1 className={h1Style}>Herman's Exclusive Interview</h1>
          <div className={videoCon}>
            <Image src={TourImage} className="img-fluid" />
            <Image
              id="sheet"
              src={SheetImage}
              className="img-fluid"
              style={{ position: "relative" }}
            />
            <div style={{ margin: "4rem 0 0 0", borderTop: "3px white solid" }}>
              <SubTitleA />
              <GalleryContainer />
            </div>
            <hr />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              {videoSection2}
              {videoSection}
            </div>{" "}
          </div>
        </section>
      </main>
    </>
  );
};

export default DesktopInterview;
