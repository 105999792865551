import React from "react";
import useMediaQuery from "../UseMediaQuery";
import DesktopInterview from "./DesktopInterview";
import InterviewStyle from "./css/Interview.module.css";
import Iframe from "react-iframe";
import TourImage from "../../images/interview-images/2024-tour.jpg";
import SheetImage from "../../images/interview-images/speaker-sheet.jpg";
import { Image } from "react-bootstrap";
import SAMobileSideVideos from "../SupstanceAbuse/SAMobileSideVIdeos";

const Interview = () => {
  //media queries
  const matchedSm = useMediaQuery("(max-width: 599px)");
  const matched1024 = useMediaQuery("(max-width: 1024px)");

  // styles
  const mainCon = InterviewStyle.mainCon;
  const imgBackground = InterviewStyle.imgBackground;
  const imgTopper = InterviewStyle.imgTopper;
  const contentCon = InterviewStyle.contentCon;
  const imgSpeakerIcon = InterviewStyle.imgSpeakerIcon;
  const h1Style = InterviewStyle.h1Style;
  const videoCon = InterviewStyle.videoCon;

  return (
    <>
      {/* mobile view */}

      {matchedSm && (
        <>
          <main id="sheet">
            <Image
              src={SheetImage}
              className="img-fluid"
              style={{ position: "relative" }}
            />
            <SAMobileSideVideos />
          </main>
          <main id="tour" className={mainCon}>
            <div className={imgBackground}></div>
            <div className={imgTopper}></div>
            <section className={contentCon}>
              <div className={imgSpeakerIcon}></div>
              <h1 className={h1Style}>
                <span>TRAVEL WITH HERMAN</span>
              </h1>
              <h1 className={h1Style}>ON THE 2024 BLACK AUTHORS MATTER TOUR</h1>
              <div className={videoCon}>
                <Image src={TourImage} className="img-fluid" />

                {/* <Iframe
                display="block"
                title="Herman's interview"
                src="https://www.youtube.com/embed/EnCXCp5QjcE?si=4LoaHml3NmrC_Osu&amp;start=35"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen // src="https://youtu.be/EnCXCp5QjcE?si=zt5kvKzcE406EsZL"
              /> */}
              </div>
            </section>
          </main>
        </>
      )}

      {/* desktop view  */}
      {!matchedSm && <DesktopInterview />}
    </>
  );
};

export default Interview;
